<template>
  <Content>
    <template #contentTitle>{{ $t("menu.print_waybill_confirm") }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.customer_number")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">{{
            pageInfo.customerNo
          }}</a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.originate")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.from">
            {{
           pageInfo.from.name
          }}
          </a-col>
        </a-row>
         <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.from_product_address")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.from">
            <span>{{ getAddressLanguageName(pageInfo.from) }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.destination_type")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">{{
            $t($enumLangkey("destinationType", pageInfo?.destinationType))
          }}</a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2" v-if="pageInfo?.destinationType!=destinationTypeEnum.OtherAddress">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.warehouse_no")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo">{{
            pageInfo.to.no
          }}</a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("logistics.recipients")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.to">
            {{ pageInfo.to?.name }}
          </a-col>
        </a-row>
        <a-row :gutter="16" class="mt-2">
          <a-col flex="0 1 150px" style="text-align: right">{{
            $t("warehouse.receive_goods_address")
          }}</a-col>
          <a-col flex="1 1 300px" v-if="pageInfo && pageInfo.to">
            <span>{{ getAddressLanguageName(pageInfo.to) }}</span>
          </a-col>
        </a-row>
        <template v-if="pageInfo?.destinationType === destinationTypeEnum.OtherAddress">
          <a-row :gutter="16" class="mt-2" v-if="pageInfo.to?.companyName">
            <a-col flex="0 1 150px" style="text-align: right">{{ $t("warehouse.recipient_company") }}</a-col>
            <a-col flex="1 1 300px" v-if="pageInfo">{{ pageInfo.to?.companyName }}</a-col>
          </a-row>
          <a-row :gutter="16" class="mt-2" v-if="pageInfo.to?.telephone">
            <a-col flex="0 1 150px" style="text-align: right">{{ $t("warehouse.phone") }}</a-col>
            <a-col flex="1 1 300px" v-if="pageInfo">{{ pageInfo.to?.telephone }}</a-col>
          </a-row>
        </template>
        <a-table
          class="mt-2"
          :columns="columns"
          size="small"
          :data-source="packingInformation"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #title>{{ $t("warehouse.encasement_info") }}</template>
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #weight="{ record }">
            {{ gToKg(record.weight) }}kg
          </template>
          <template #size="{ record }">
            {{ record.length }}x{{ record.width }}x{{ record.height }}cm
          </template>
          <template #girth="{ record }"> {{ record.girth }}cm </template>
          <template #volume="{ record }">
            {{ cmCubicToM(record.volume) }}m<sup>3</sup>
          </template>
          <template #footer>
            <a-row :gutter="48" type="flex" justify="end">
              <a-col v-if="pageInfo"
                >{{ $t("warehouse.total_weight") }}:
                {{ gToKg(pageInfo.totalWeight) }}kg</a-col
              >
              <a-col v-if="pageInfo"
                >{{ $t("warehouse.total_volume") }}:
                {{ cmCubicToM(pageInfo.totalVolume) }}m<sup>3</sup></a-col
              >
            </a-row>
          </template>
        </a-table>
        <div class="mt-3">
          <a-button class="mr-3" :loading="allChannelTableLoading" @click="handleChooseChannel">{{
            $t("menu.print_waybill_confirm")
          }}</a-button>
          {{ $t("warehouse.transport_characteristics") }}:
          <a-space v-if="pageInfo">
            <span
              v-for="(item, index) in pageInfo.tranPlanCharacteristics"
              :key="index"
              >{{
                $t($enumLangkey("transportCharacteristics", item))
              }}</span
            >
          </a-space>
        </div>
        <!-- 可选渠道列表 -->
        <a-table
          v-if="showAllChannelTable"
          class="mt-3"
          :columns="channelColumns"
          size="small"
          :data-source="allChannelTable"
          :loading="allChannelTableLoading"
          :pagination="false"
          :locale="{ emptyText: $t('warehouse.no_waybill_channel_available_at_present') }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #operation="{ record }">
            <a-button
              size="small"
              :type="record.isSelected ? 'primary' : 'default'"
              :disabled="!record.logisticsBaseInfo.isEnable"
              @click="handleConfirmChannel(record)"
            >
              {{
                record.isSelected ? $t("common.selected") : $t("common.select")
              }}
            </a-button>
            <div v-if="!record.logisticsBaseInfo.isEnable" class="mt-1" style="color: #AAAAAA;">{{ $t('warehouse.not_applicable') }}</div>
          </template>
          <template #logisticsId="{ record }">
            <div>{{ record.logisticsBaseInfo.code }}</div>
            <a-tag class="mt-1" color="#f59b22" v-if="record.logisticsBaseInfo.isSupportCommercialAddr">{{ $t("logistics.business_site") }}</a-tag>
            <a-tag class="mt-1" color="#2db7f5" v-if="record.logisticsBaseInfo.isSupportResidentialAddr">{{ $t("logistics.residence") }}</a-tag>
          </template>
          <template #logisticsName="{ record }">
            <div style="font-weight: bold; color: orange">
              {{ record.logisticsBaseInfo.name }}
            </div>
            <a-row :gutter="[8, 4]">
              <a-col v-for="(item, index) in record.logisticsBaseInfo.descriptionItems" :key="index">
                <CheckCircleOutlined style="color: orange" />
                <span class="ml-2" style="color: #bbbbbb">{{ item }}</span>
              </a-col>
            </a-row>
          </template>
          <template #logisticsType="{ record }">
            {{
              $t(
                $enumLangkey(
                  "logisticsWay",
                  record.logisticsBaseInfo.logisticsType
                )
              )
            }}
          </template>
          <template #deliveryType="{ record }">
            {{
              $t(
                $enumLangkey(
                  "deliveryWay",
                  record.logisticsBaseInfo.deliveryType
                )
              )
            }}
          </template>
          <template #referenceAging="{ record }">
            {{ record.logisticsBaseInfo.daysMin }}-{{
              record.logisticsBaseInfo.daysMax
            }}{{ $t("warehouse.workday") }}({{ $t("enum.aging_0") }})
          </template>
          <template #freights="{ record }">
            <div style="color: orange">
              {{ $t("warehouse.average_unit_price") }}:
              {{ record.logisticsBaseInfo.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo.averagePrice) }}
            </div>
            <div style="color: orange">
              {{ $t("warehouse.total_prices") }}:
              {{ record.logisticsBaseInfo.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo.totalFeeWithoutServices) }}
            </div>
            <div style="color: #aaaaaa">
              {{ $t("logistics.estimated_freight_tips") }}
            </div>
          </template>
          <template #details="{ record }">
            <div>
              {{ $t("logistics.chargeable_weight") }}:
              {{ gToKg(record.logisticsBaseInfo.chargeableWeight) }}kg
            </div>
            <div>
              {{ $t("logistics.volume_weight_coefficient") }}:
              {{ record.logisticsBaseInfo.volumeWeightParam }}
            </div>
          </template>
          <template #canChooseServerInfoes="{ record }">
            <div v-if="record.logisticsRegionInfo.isDdpSupported">
              {{
                $t(
                  $enumLangkey(
                    "orderLogisticsFee",
                    record.logisticsRegionInfo.ddpFeeType
                  )
                )
              }}: {{ record.logisticsBaseInfo.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee) }}
            </div>
            <CSignSupported
              v-if="record.logisticsRegionInfo.isSignSupported"
              type="preview"
              :symbol="record.logisticsBaseInfo.currencySymbol"
              :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
            />
          </template>
        </a-table>
        <!-- 已选渠道列表 -->
        <a-table
          v-if="!showAllChannelTable"
          class="mt-3"
          :columns="secondColumns"
          size="small"
          :data-source="channelTable"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #logisticsId="{ record }">
            {{ record.logisticsBaseInfo.code }}
          </template>
          <template #logisticsName="{ record }">
            <span style="color: orange; font-weight: bold">{{
              record.logisticsBaseInfo.name
            }}</span>
          </template>
          <template #logisticsType="{ record }">
            {{
              $t(
                $enumLangkey(
                  "logisticsWay",
                  record.logisticsBaseInfo.logisticsType
                )
              )
            }}
          </template>
          <template #deliveryType="{ record }">
            {{
              $t(
                $enumLangkey(
                  "deliveryWay",
                  record.logisticsBaseInfo.deliveryType
                )
              )
            }}
          </template>
          <template #referenceAging="{ record }">
            {{ record.logisticsBaseInfo.daysMin }}-{{
              record.logisticsBaseInfo.daysMax
            }}{{ $t("warehouse.workday") }}({{ $t("enum.aging_0") }})
          </template>
          <template #freights="{ record }">
            <div style="color: orange">
              {{ $t("warehouse.average_unit_price") }}:
              {{ record.logisticsBaseInfo.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo.averagePrice) }}
            </div>
            <div style="color: orange">
              {{ $t("warehouse.total_prices") }}:
              {{ record.logisticsBaseInfo.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo.totalFeeWithoutServices) }}
            </div>
          </template>
          <template #details="{ record }">
            <div>
              {{ $t("logistics.chargeable_weight") }}:
              {{ gToKg(record.logisticsBaseInfo.chargeableWeight) }}kg
            </div>
            <div>
              {{ $t("logistics.volume_weight_coefficient") }}:
              {{ record.logisticsBaseInfo.volumeWeightParam }}
            </div>
          </template>
          <template #canChooseServerInfoes="{ record }">
            <div v-if="record.logisticsRegionInfo.isDdpSupported">
              <a-checkbox v-model:checked="selectOptionalServicesState.chooseDdp">{{
                  $t(
                    $enumLangkey(
                      "orderLogisticsFee",
                      record.logisticsRegionInfo.ddpFeeType
                    )
                  )
                }}: {{ record.logisticsBaseInfo.currencySymbol
                }}{{ $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee) }}</a-checkbox>
            </div>
            <CSignSupported
              v-if="record.logisticsRegionInfo.isSignSupported"
              v-model:value="selectOptionalServicesState.signatureType"
              type="editable"
              :symbol="record.currencySymbol"
              :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
            />
          </template>
        </a-table>
        <div v-if="isShowVat" class="mt-3">
                  <span style="color: red">*</span>
                  <label> VAT: </label>
                  <a-input
                    v-model:value="vat"
                    style="max-width: 150px"
                  />
        </div>
        <CFee v-if="pageInfo" class="mt-5" :fees="getFeeList()" :totalFee="getTotalFeeObj()" />
      </a-spin>
      <a-row type="flex" justify="end" class="mt-3 mr-5">
        <a-col>
          <a-button v-if="isEmptyChannel" @click="handleClose">{{
            $t("common.backtrack")
          }}</a-button>
          <a-button v-else type="primary" @click="handleShowPayment" :disabled="channelTable.length === 0">{{
            $t("logistics.place_an_order_and_get_a_transfer")
          }}</a-button>
        </a-col>
      </a-row>
      <Payment
        ref="refPayment"
        v-model:visible="payModal.visible"
        :loading="payModal.loading"
        :currencyId="feeData?.currencyId ?? ''"
        :unpaidSymbol="feeData?.currencySymbol ?? ''"
        :unpaidSum="getTotalFeeObj()?.value ?? 0"
        @handlePayFee="handleOrderPay"
      />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted,onActivated, reactive, toRefs, ref, watch } from "vue";
import {
  Spin,
  Space,
  Table,
  Row,
  Col,
  Divider,
  Button,
  Tag,
  Select,
  Checkbox,
  message,Input
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import Payment from "@/views/components/Payment";
import CFee from "@/views/components/CFee";
import CSignSupported from "@/views/components/CSignSupported";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useTab } from "@/hooks/tabs/index";
import {
  getById,
  getSupplierLogisticByWayBill,
  getSupplierLogisticByWayBillChooseOne,
  updateChooseSupplierLogistic,
} from "@/api/modules/printWaybill/list";
import { gToKg, cmCubicToM, getAddressByLanguageV2 } from "@/utils/general";
import {
  destinationType as destinationTypeEnum
} from "../../enum/enum.json";
import BigNumber from "bignumber.js";
import filters from "@/filters";
export default defineComponent({
  name: "print_waybill_confirm",
  components: {
    ASpin: Spin,
    ASpace: Space,
    ATable: Table,
    ARow: Row,
    ACol: Col,
    ADivider: Divider,
    AButton: Button,
    ATag: Tag,
    ASelect: Select,
    ASelectOption: Select.Option,
    ACheckbox: Checkbox,
    Content,
    Payment,
    CFee,
    CSignSupported,
    AInput:Input
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const refPayment = ref(null);

    const state = reactive({
      loading: true,
      pageInfo: null,
      packingInformation: [],
      channelTable: [],
      showAllChannelTable: true,
      allChannelTableLoading: false,
      allChannelTable: [],
      isEmptyChannel: false,
      // 选择渠道的可选服务
      selectOptionalServicesData: null,
      // 选中的可选服务
      selectOptionalServicesState: {
        id: false,
        chooseDdp: false,
        chooseSignSupported: false,
        signatureType: null,
      },
      selectOptionalServicesList: [],
      payModal: {
        visible: false,
        loading: false,
      },
      feeData: {
        currencySymbol: null,
        totalAmount: null,
        currencyId: null,
      },
      vat:null,
      isShowVat:false
    });

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 80,
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        title: () => vueI18n.t("warehouse.actual_weight"),
        width: 150,
        slots: {
          customRender: "weight",
        },
      },
      {
        title: () => vueI18n.t("logistics.size"),
        width: 150,
        slots: {
          customRender: "size",
        },
      },
      {
        title: () => vueI18n.t("warehouse.girth"),
        width: 150,
        slots: {
          customRender: "girth",
        },
      },
      {
        title: () => vueI18n.t("warehouse.volume"),
        width: 150,
        slots: {
          customRender: "volume",
        },
      },
    ];

    const secondColumns = [
      {
        title: () => vueI18n.t("warehouse.channel_code"),
        width: 150,
        slots: {
          customRender: "logisticsId",
        },
      },
      {
        title: () => vueI18n.t("warehouse.channel_name"),
        width: 200,
        slots: {
          customRender: "logisticsName",
        },
      },
      {
        title: () => vueI18n.t("warehouse.logistics_type"),
        width: 100,
        slots: {
          customRender: "logisticsType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.delivery_way"),
        width: 100,
        slots: {
          customRender: "deliveryType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.reference_aging"),
        width: 100,
        slots: {
          customRender: "referenceAging",
        },
      },
      {
        title: () => vueI18n.t("warehouse.freight"),
        width: 150,
        slots: {
          customRender: "freights",
        },
      },
      {
        title: () => vueI18n.t("common.details"),
        width: 150,
        slots: {
          customRender: "details",
        },
      },
      {
        title: () => vueI18n.t("warehouse.optional_services"),
        width: 120,
        slots: {
          customRender: "canChooseServerInfoes",
        },
      },
    ];

    const channelColumns = [
      {
        width: 80,
        slots: {
          customRender: "operation",
        },
      },
      ...secondColumns,
    ];

    const getTotalFeeObj = () => {
      let feeList = getFeeList()
      let symbol = feeList.length > 0 ? feeList[0].symbol : '';
      let value = feeList.reduce((total, item) => {
        return total.plus(item.value)
      }, new BigNumber(0)).toNumber();
      return { symbol, value, checkValue: null };
    }

    const getFeeList = () => {
      let list = []
      if (state.pageInfo && Array.isArray(state.pageInfo.warehouseFrees)) {
        list = list.concat(state.pageInfo.warehouseFrees)
      }
      if (Array.isArray(state.selectOptionalServicesList)) {
        list = list.concat(state.selectOptionalServicesList)
      }
      return list.map(item => ({
        feeType: item.feeType,
        symbol: item.symbol,
        value: item.value,
        checkValue: null,
      }))
    }

    const handleClose = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "print_waybill_list" });
    };

    const getLanguageName = (cnName = "", enName = "") => {
      if (getters.language === "zh-CN") {
        return cnName;
      }
      return enName;
    };

    const getAddressLanguageName = (data) => {
      if (typeof data !== "object") {
        return "";
      }
      return getAddressByLanguageV2(data, getters.language);
    };

    const handleChooseChannel = () => {
      state.allChannelTableLoading = true;
      state.showAllChannelTable = true;
      state.isShowVat = false;
      // 清空数据
      state.selectOptionalServicesList = []
      state.selectOptionalServicesState.chooseDdp = false
      state.selectOptionalServicesState.chooseSignSupported = false
      state.selectOptionalServicesState.signatureType = null
      state.channelTable = []
      getSupplierLogisticByWayBill({ planId: route.params.id })
        .then(({ result }) => {
          if (result) {
            state.allChannelTable = result.map((item) => {
              item.isSelected = false;
              // if (state.channelTable.length > 0) {
              //   if (
              //     item.logisticsBaseInfo.id ===
              //     state.channelTable[0].logisticsBaseInfo.id &&
              //     item.logisticsBaseInfo.isEnable
              //   ) {
              //     item.isSelected = true;
              //   } else {
              //     state.channelTable = []
              //   }
              // }
              return item;
            });
            state.isEmptyChannel = result.length ? false : true
          }
        })
        .finally(() => {
          state.allChannelTableLoading = false;
        });
    };

    watch(() => [state.selectOptionalServicesState.chooseDdp, state.selectOptionalServicesState.signatureType], ([chooseDdp, signatureType]) => {
      if (state.channelTable.length === 0) {
        return;
      }
      let list = [];
      let record = state.channelTable.find(item => item.logisticsBaseInfo.id === state.selectOptionalServicesState.id);
      if (record) {
        let { logisticsRegionInfo, logisticsBaseInfo } = record;
        if (record.logisticsRegionInfo.isDdpSupported && chooseDdp) {
          list.push({
            feeType: logisticsRegionInfo.ddpFeeType,
            symbol: logisticsBaseInfo.currencySymbol,
            value: logisticsRegionInfo.ddpFee,
          });
        }

        if (logisticsRegionInfo.isSignSupported && signatureType > 0) {
          let selectedSignFeeItem =logisticsRegionInfo.signFeeDtos.find(item => item.signatureType === signatureType);
          if (selectedSignFeeItem) {
            list.push({
              feeType: logisticsRegionInfo.signFeeType,
              symbol: logisticsBaseInfo.currencySymbol,
              value: selectedSignFeeItem.fee,
            });
          }
        }
      }
      state.selectOptionalServicesList = list;
    })

    const handleConfirmChannel = (record) => {
      state.showAllChannelTable = false;
      state.isShowVat = false;
      state.vat = null;
      if (record.isSelected) {
        return;
      }
      state.selectOptionalServicesState.id = record.logisticsBaseInfo.id;
      state.loading = true
      const data = {
        planId: route.params.id,
        logisticId: record.logisticsBaseInfo.id
      }
      getSupplierLogisticByWayBillChooseOne(data).then(({ result }) => {
        if (result) {
          let list = []
          let symbol = '';
          let { fees, logistic } = result
          if (fees && Array.isArray(fees)) {
            list = fees.map(item => {
              if (!symbol) {
                symbol = item.symbol
              }
              return item
            })
          }
          if (logistic) {
            let { logisticsBaseInfo, logisticsRegionInfo } = logistic
            if (logisticsBaseInfo && logisticsRegionInfo) {
              state.feeData.currencyId = logisticsBaseInfo.currencyId
              state.channelTable = [Object.assign({}, { logisticsBaseInfo, logisticsRegionInfo })];
            }
          }
          state.isShowVat = logistic?.logisticsRegionInfo?.isVatRequired;
          let newList = []
          if (list.length) {
            newList.push(Object.assign({}, list[0]))
            for (let i = 1; i < list.length; i++) {
              let index = newList.findIndex(item => item.feeType === list[i].feeType)
              if (index > -1) {
                newList[index].value += list[i].value
              } else {
                newList.push(Object.assign({}, list[i]))
              }
            }
          }
          state.feeData.currencySymbol = symbol
          state.pageInfo.warehouseFrees = newList
        }
      }).finally(() => {
        state.loading = false
      })
    };

    const handleShowPayment = () => {
      if (state.channelTable.length === 0) {
        message.error(vueI18n.t("logistics.please_select_channel"));
        return;
      }
      
      if (state.isShowVat && (!state.vat||state.vat?.trim()?.length==0)) {
        message.error(vueI18n.t("common.p0_is_required",["VAT"]));
        return
      }

      state.payModal.visible = true
      refPayment.value.handleShowPayOrder();
    };

    const handleOrderPay = () => {
      const data = Object.assign({}, state.selectOptionalServicesState, {
        id: route.params.id,
        vatCode:state.vat,
        payFee:filters.amountToFixed2(getTotalFeeObj()?.value),
        supplierLogisticId: state.channelTable[0].logisticsBaseInfo.id,
      })
      data.chooseSignSupported = data.signatureType > 0 ? true : false;
      state.payModal.loading = true;
      updateChooseSupplierLogistic(data)
        .then(() => {
          state.payModal.visible = false
          message.success(vueI18n.t('common.succeed'))
          handleClose()
        })
        .finally(() => {
          state.payModal.loading = false;
        });
    };

    onActivated(() => {
      let id = route.params.id;
      if (id) {
        state.loading = true;
        getById({ id })
          .then(({ result }) => {
            if (result) {
              let { details = [], ...info } = result;
              state.packingInformation = details;
              state.pageInfo = info;
              // 查询渠道
              handleChooseChannel()
            } else {
              message.error(vueI18n.t("common.exception"));
            }
          })
          .finally(() => {
            state.loading = false;
          });
      } else {
        message.error(vueI18n.t("common.exception"));
      }
    });

    return {
      ...toRefs(state),
      destinationTypeEnum,
      columns,
      secondColumns,
      channelColumns,
      refPayment,
      labelCol: { style: { width: "150px" } },
      wrapperCol: { style: { width: "calc(100% - 150px)" } },
      gToKg,
      cmCubicToM,
      getLanguageName,
      getAddressLanguageName,
      handleChooseChannel,
      handleConfirmChannel,
      handleShowPayment,
      handleOrderPay,
      handleClose,
      getTotalFeeObj,
      getFeeList,
    };
  },
});
</script>
