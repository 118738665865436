import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 列表
  getPagedList: "/api/WarehouseService/WaybillPlan/GetPagedList",
  // 打印运单
  printWaybillPlan: "/api/WarehouseService/WaybillPlan/PrintWaybillPlan",
  // 取消运单
  updateWaybillOperationStatus:
    "/api/WarehouseService/WaybillPlan/UpdateWaybillOperationStatus",
  // 获取运单轨迹
  getWaybillTrackList: "/api/WarehouseService/WaybillPlan/GetWaybillTrackList",
  // 详情
  getById: "/api/WarehouseService/WaybillPlan/Get",
  // 供应商物流列表
  getSupplierLogisticByWayBill:
    "/api/WarehouseService/SupplierLogistics/GetSupplierLogisticByWayBill",
  // 供应商物流详情
  getSupplierLogisticByWayBillChooseOne:
    "/api/WarehouseService/SupplierLogistics/GetSupplierLogisticByWayBillChooseOne",
  // 选择物流并支付
  updateChooseSupplierLogistic:
    "/api/WarehouseService/WaybillPlan/UpdateChooseSupplierLogistic",
  // 导出运单列表
  exportWayBill: "/api/WarehouseService/WaybillPlan/ExportWayBill",
};

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: "POST",
  });
};

export const printWaybillPlan = (data) => {
  return client.request({
    url: Api.printWaybillPlan,
    params: data,
    method: "GET",
  });
};

export const updateWaybillOperationStatus = (data) => {
  return client.request({
    url: Api.updateWaybillOperationStatus,
    data,
    method: "POST",
  });
};

export const getWaybillTrackList = (data) => {
  return client.request({
    url: Api.getWaybillTrackList,
    params: data,
    method: "GET",
  });
};

export const getById = (data) => {
  return client.request({
    url: Api.getById,
    params: data,
    method: "GET",
  });
};

export const getSupplierLogisticByWayBill = (data) => {
  return client.request({
    url: Api.getSupplierLogisticByWayBill,
    data,
    method: "POST",
  });
};

export const getSupplierLogisticByWayBillChooseOne = (data) => {
  return client.request({
    url: Api.getSupplierLogisticByWayBillChooseOne,
    data,
    method: "POST",
  });
};

export const updateChooseSupplierLogistic = (data) => {
  return client.request({
    url: Api.updateChooseSupplierLogistic,
    data,
    method: "POST",
  });
};

export const exportWayBill = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportWayBill);
  return url;
};
